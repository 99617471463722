

import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';

export default defineComponent({
    mixins: [Base],
    props: {
        modelValue: {
            type: Object,
            default: undefined,
        },
        required: {
            type: Boolean,
            default: false,
        },
        withAutocomplete: {
            type: Boolean,
            default: false,
        },
        withCurrentPosition: {
            type: Boolean,
            default: false,
        },
        withMap: {
            type: Boolean,
            default: false,
        },
        country: {
            type: Object,
            default: () => undefined,
        },
        expanded: {
            type: Boolean,
            default: true,
        },
        latParam: {
            type: String,
            default: 'lat',
        },
        lngParam: {
            type: String,
            default: 'lng',
        },
        streetParam: {
            type: String,
            default: 'street',
        },
        numberParam: {
            type: String,
            default: 'number',
        },
        boxParam: {
            type: String,
            default: 'box',
        },
        postalCodeParam: {
            type: String,
            default: 'postal_code',
        },
        cityParam: {
            type: String,
            default: 'city',
        },
        countryParam: {
            type: String,
            default: 'country',
        },
        regionParam: {
            type: String,
            default: 'region',
        },
        error: {
            type: [Object, Array, Boolean, String],
            default: false,
        },
    },
    data() {
        return {
            lat: 0,
            lng: 0,
            newModelValue: undefined,
            map: undefined as any,
            marker: undefined as any,
            isMapExpanded: false,
            errors: this.error,
        };
    },
    mounted() {
        this.newModelValue = this.modelValue ? this.modelValue : {};

        if (this.country) {
            this.newModelValue[this.countryParam] = this.country;
        }
        setTimeout(this.loadMap, 100);

        this.lat = this.newModelValue[this.latParam] ? parseFloat(this.newModelValue[this.latParam]) : 0;
        this.lng = this.newModelValue[this.lngParam] ? parseFloat(this.newModelValue[this.lngParam]) : 0;
    },

    methods: {
        parseAddressComponents(place: any): void {
            if (place.address_components) {
                place.address_components.forEach((comp: any) => {
                    if (comp.types[0] === 'street_number') this.newModelValue[this.numberParam] = comp.long_name;
                    if (comp.types[0] === 'postal_code') this.newModelValue[this.postalCodeParam] = comp.long_name;
                    if (comp.types[0] === 'country') {
                        switch (comp.long_name.toLowerCase()) {
                        case 'belgium':
                            this.newModelValue[this.countryParam] = {
                                id: 1,
                                name: 'Belgium',
                                };
                            this.newModelValue[`${this.countryParam}_id`] = 1;
                            break;
                        case 'france':
                            this.newModelValue[this.countryParam] = {
                                id: 2,
                                name: 'France',
                                };
                            this.newModelValue[`${this.countryParam}_id`] = 2;
                            break;
                        case 'netherlands':
                            this.newModelValue[this.countryParam] = {
                                id: 3,
                                name: 'Netherlands',
                                };
                            this.newModelValue[`${this.countryParam}_id`] = 3;
                            break;
                        default:
                            this.newModelValue[this.countryParam] = undefined;
                            break;
                        }
                    }

                    if (comp.types[0] === 'administrative_area_level_1') {
                        switch (comp.long_name) {
                        case 'Vlaams Gewest':
                            this.newModelValue[this.regionParam] = {
                                id: 1,
                                name: 'Flanders',
                                };
                            this.newModelValue[`${this.regionParam}_id`] = 1;
                            break;
                        case 'Bruxelles':
                            this.newModelValue[this.regionParam] = {
                                id: 2,
                                name: 'Brussels',
                                };
                            this.newModelValue[`${this.regionParam}_id`] = 2;
                            break;
                        case 'Brussels':
                            this.newModelValue[this.regionParam] = {
                                id: 2,
                                name: 'Brussels',
                                };
                            this.newModelValue[`${this.regionParam}_id`] = 2;
                            break;
                        case 'Région Wallonne':
                            this.newModelValue[this.regionParam] = {
                                id: 3,
                                name: 'Wallonia',
                                };
                            this.newModelValue[`${this.regionParam}_id`] = 3;
                            break;
                        default:
                            break;
                        }
                    }
                    if (comp.types[0] === 'route') {
                        (this.$refs as any).street.$el.firstChild.value = comp.long_name;
                        this.newModelValue[this.streetParam] = comp.long_name;
                    }
                    if (comp.types[0] === 'locality') this.newModelValue[this.cityParam] = comp.long_name;
                });
            }
            if (place.geometry) {
                if (this.map) {
                    this.map.setCenter(place.geometry.location);
                    this.map.setZoom(12);

                    this.marker.setPosition(place.geometry.location);
                }

                this.newModelValue[this.latParam] = place.geometry.location.lat();
                this.newModelValue[this.lngParam] = place.geometry.location.lng();
            }
        },
        loadMap(): void {
            if ((window as any).google && (this.withMap || this.withAutocomplete || this.withCurrentPosition)) {
                if (this.withMap) {
                    this.map = new (window as any).google.maps.Map(this.$refs.map, {
                        center: {
                            lat: this.lat,
                            lng: this.lng,
                        },
                        zoom: 8,
                        disableDefaultUI: true,
                    });

                    this.marker = new (window as any).google.maps.Marker({
                        map: this.map,
                        position: new (window as any).google.maps.LatLng(this.lat, this.lng),
                    });
                }

                if (this.withAutocomplete) {
                    if ((this.$refs as any).street.$el) {
                        const el = (this.$refs as any).street.$el.firstChild;
                        const autocomplete = new (window as any).google.maps.places.Autocomplete(el, {
                            columns: ['address_components', 'geometry'],
                            componentRestrictions: { country: ['be', 'fr', 'nl'] },
                        });

                        autocomplete.addListener('place_changed', () => {
                            const place = autocomplete.getPlace();
                            this.parseAddressComponents(place);
                        });
                    }
                }
            }
        },
        updateLocation(): void {
            const geocoder = new (window as any).google.maps.Geocoder();

            const address = `${this.newModelValue[this.streetParam]
            } ${this.newModelValue[this.numberParam]
            } ${this.newModelValue[this.postalCodeParam]
            } ${this.newModelValue[this.cityParam]}
            } ${this.newModelValue[this.countryParam]}`;

            geocoder.geocode({
                address,
            }, (results: any, status: string) => {
                if (status === 'OK') {
                    this.parseAddressComponents(results[0]);
                    this.$emit('update:modelValue', this.newModelValue);
                } else {
                    this.$emit('error', {
                        message: 'Cannot find address',
                    });
                }
            });
        },
        getCurrentLocation(): void {
            if (!('geolocation' in navigator)) {
                this.errors.message = 'Geolocation is not available now.';
                return;
            }

            navigator.geolocation.getCurrentPosition((pos) => {
                this.loading = false;
                const coordinates = {
                    lat: pos.coords.latitude,
                    lng: pos.coords.longitude,
                };

                const geocoder = new (window as any).google.maps.Geocoder();

                geocoder.geocode({ location: coordinates }, (results: any[], status: string) => {
                    if (status === 'OK') {
                        this.parseAddressComponents(results[0]);
                    }
                });
            }, (err) => {
                this.errors.message = err.message;
                this.loading = false;
            });
        },
    },
    watch: {
        error(value) {
            this.errors = value;
        },
        country(value) {
            this.newModelValue[this.countryParam] = value;
        },
        modelValue(value) {
            this.newModelValue = value || {};
        },
    },
});
