import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "tags my-2" }
const _hoisted_2 = {
  key: 0,
  class: "tag is-black is-rounded"
}
const _hoisted_3 = { class: "has-text-primary ml-2" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (param, p) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: p }, [
        (param && p !== 'page')
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createTextVNode(_toDisplayString(_ctx.$filters.sentenceCase(p)) + ": ", 1),
              _createElementVNode("strong", _hoisted_3, _toDisplayString(_ctx.$filters.truncate(_ctx.getValue(param), 20)), 1),
              _createElementVNode("button", {
                class: "delete is-small",
                type: "button",
                onClick: ($event: any) => {_ctx.modelValue[p] = ''; _ctx.$emit('update:modelValue', _ctx.modelValue)}
              }, null, 8, _hoisted_4)
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}