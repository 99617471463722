import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "control is-expanded" }
const _hoisted_4 = { class: "field has-addons" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_input = _resolveComponent("o-input")!
  const _component_o_field = _resolveComponent("o-field")!
  const _component_vee_field = _resolveComponent("vee-field")!
  const _component_o_icon = _resolveComponent("o-icon")!
  const _component_o_button = _resolveComponent("o-button")!
  const _component_o_tooltip = _resolveComponent("o-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.locales, (locale, l) => {
          return (_openBlock(), _createBlock(_component_vee_field, {
            key: l,
            modelValue: _ctx.newValue[locale.code],
            "onUpdate:modelValue": ($event: any) => ((_ctx.newValue[locale.code]) = $event),
            name: _ctx.name + '[' + locale.code + ']',
            rules: _ctx.rules,
            "validate-on-mount": ""
          }, {
            default: _withCtx(({ errors, field, handleChange }) => [
              _withDirectives(_createVNode(_component_o_field, {
                message: errors[0] ? errors[0] : null,
                variant: errors[0] ? 'danger' : null,
                class: "is-fullwidth"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_o_input, {
                    disabled: _ctx.loading,
                    "model-value": field.value,
                    placeholder: _ctx.placeholder ?? locale.name,
                    type: _ctx.type,
                    expanded: "",
                    "onUpdate:modelValue": handleChange
                  }, null, 8, ["disabled", "model-value", "placeholder", "type", "onUpdate:modelValue"])
                ]),
                _: 2
              }, 1032, ["message", "variant"]), [
                [_vShow, locale.code == _ctx.$store.state.currentLocale]
              ])
            ]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue", "name", "rules"]))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.locales, (locale, l) => {
          return (_openBlock(), _createBlock(_component_o_tooltip, {
            key: l,
            label: locale.name,
            position: "top",
            variant: "dark"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_o_button, {
                variant: locale.code === _ctx.$store.state.currentLocale ? 'grey' : 'light',
                class: "p-2",
                onClick: ($event: any) => (_ctx.changeLocale(locale.code)),
                onMouseenter: ($event: any) => (_ctx.startPreview(locale.code)),
                onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.endPreview()))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: locale.flag,
                    style: { height: 12 + 'px' }
                  }, null, 8, _hoisted_5),
                  (!!_ctx.errors[_ctx.name + '[' + locale.code + ']'])
                    ? (_openBlock(), _createBlock(_component_o_icon, {
                        key: 0,
                        style: {
                                    position: 'absolute',
                                    top: -6 + 'px',
                                    right: 6 + 'px',
                                },
                        icon: "circle-exclamation",
                        variant: "danger"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["variant", "onClick", "onMouseenter"])
            ]),
            _: 2
          }, 1032, ["label"]))
        }), 128))
      ])
    ])
  ]))
}