import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "is-flex is-align-items-center" }
const _hoisted_2 = { class: "image is-16x16 mr-2" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_autocomplete = _resolveComponent("o-autocomplete")!

  return (_openBlock(), _createBlock(_component_o_autocomplete, {
    ref: "dropdown",
    modelValue: _ctx.newModelValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newModelValue) = $event)),
    "append-to-body": _ctx.appendToBody,
    "check-infinite-scroll": true,
    clearable: true,
    data: _ctx.data,
    field: 'name',
    icon: 'globe-europe',
    "icon-right": 'caret-down',
    loading: _ctx.loading,
    "open-on-focus": true,
    placeholder: "Country",
    onFocus: _ctx.onFocus,
    onSelect: _ctx.onSelect,
    onTyping: _ctx.onTyping,
    onInfiniteScroll: _ctx.fetchMoreData
  }, {
    default: _withCtx((props) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: '/img/flags/' + props.option.code + '.svg',
            alt: "",
            class: "is-fullheight"
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("span", null, _toDisplayString(props.option.name), 1)
      ])
    ]),
    empty: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, " Loading... "))
        : (_openBlock(), _createElementBlock("p", _hoisted_5, " No matching results "))
    ]),
    _: 1
  }, 8, ["modelValue", "append-to-body", "data", "loading", "onFocus", "onSelect", "onTyping", "onInfiniteScroll"]))
}