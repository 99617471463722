
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        rules: {
            type: String,
            default: null,
        },
        errors: {
            required: true,
            type: [Object, Boolean],
            default: () => {
            },
        },
        name: {
            required: true,
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        label: {
            required: true,
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'input',
        },
        loading: {
            required: true,
            type: Boolean,
            default: false,
        },
        modelValue: {
            required: true,
            type: Object,
            default: () => {
            },
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            newValue: this.modelValue,
            currentLocale: this.$store.state.currentLocale,
        };
    },
    methods: {
        startPreview(locale) {
            this.$store.state.currentLocale = locale;
        },
        endPreview() {
            this.$store.state.currentLocale = this.currentLocale;
        },
        changeLocale(locale) {
            this.$store.state.currentLocale = locale;
            this.currentLocale = locale;
        },
    },
});
