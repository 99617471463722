import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mr-2" }
const _hoisted_2 = { class: "mr-2" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(!_ctx.multiple ? 'o-autocomplete' : 'o-inputitems'), {
    ref: "dropdown",
    modelValue: _ctx.newModelValue,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newModelValue) = $event)),
      _ctx.onInput
    ],
    "append-to-body": _ctx.appendToBody,
    autocomplete: _ctx.multiple ? 'autocomplete' : null,
    "check-infinite-scroll": true,
    clearable: true,
    closeIcon: '',
    createItem: _ctx.createItem,
    data: _ctx.data,
    field: 'name',
    icon: 'exclamation-triangle',
    "icon-right": 'caret-down',
    loading: _ctx.loading,
    "open-on-focus": true,
    placeholder: "Infringement",
    onSelect: _ctx.onSelect,
    onTyping: _ctx.onTyping,
    onInfiniteScroll: _ctx.fetchMoreData,
    onFocus: _withModifiers(_ctx.onFocus, ["self"])
  }, {
    default: _withCtx((props) => [
      _createElementVNode("strong", _hoisted_1, _toDisplayString(props.option.code), 1),
      _createElementVNode("span", null, _toDisplayString(props.option.name), 1)
    ]),
    selected: _withCtx(({ items }) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items, (item, i) => {
        return (_openBlock(), _createElementBlock("span", {
          key: i,
          class: "tag",
          rounded: ""
        }, [
          _createElementVNode("strong", _hoisted_2, _toDisplayString(item.code), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$filters.truncate(item.name, 15)), 1),
          _createElementVNode("span", {
            class: "icon is-clickable delete is-small",
            onClick: ($event: any) => (_ctx.$refs.infringement.removeItem(i, $event))
          }, null, 8, _hoisted_3)
        ]))
      }), 128))
    ]),
    empty: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, " Loading... "))
        : (_openBlock(), _createElementBlock("p", _hoisted_5, " No matching results "))
    ]),
    _: 1
  }, 40, ["modelValue", "append-to-body", "autocomplete", "createItem", "data", "loading", "onSelect", "onTyping", "onInfiniteScroll", "onUpdate:modelValue", "onFocus"]))
}