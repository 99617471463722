

import { debounce } from 'ts-debounce';

import { defineComponent, PropType } from 'vue';

import { IInfringementsResponse } from '@/interfaces/infringement';
import Base from '@/mixins/Base.vue';

export default defineComponent({
    mixins: [Base],
    props: {
        appendToBody: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: undefined as any | PropType<unknown> | PropType<Array<any>>,
            default: undefined,
        },
        type: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            name: '',
            page: 1,
            totalPages: 1,
            newModelValue: undefined,
            data: [] as Array<any>,
        };
    },
    mounted() {
        if (!this.multiple) {
            this.newModelValue = this.modelValue ? this.modelValue.name : '';
        } else {
            this.newModelValue = this.modelValue ? this.modelValue : [];
        }

        if (!this.modelValue) this.$emit('update:modelValue', this.multiple ? [] : null);
    },
    methods: {
        reset(): void {
            this.page = 1;
            this.totalPages = 1;
            this.name = '';
            this.data = [];
            this.fetchData();
        },
        createItem(event: any): any {
            return (typeof event !== 'string') ? event : {
                id: '',
                name: event,
            };
        },
        onFocus(): void {
            this.reset();
        },
        onSelect(obj: any): void {
            if (!obj) this.reset();
            if (!this.multiple) this.$emit('update:modelValue', obj || null);
        },
        onInput(obj: any): void {
            if (this.multiple) this.$emit('update:modelValue', obj || []);
        },
        // eslint-disable-next-line func-names
        onTyping: debounce(function (this: any, name = ''): void {
            this.fetchData(name);
        }, 500),

        fetchData(name = '') {
            if (this.name !== name) {
                this.name = name;
                this.page = 1;
                this.totalPages = 1;
                this.data = [];
            }

            if (this.page > this.totalPages) {
                return;
            }

            this.get<IInfringementsResponse>('infringements', {
                params: {
                    filter: this.name,
                    page: this.page,
                    type: this.type,
                },
            })
                .then(({ data }: any) => {
                    this.data.push(...data.data);
                    this.page += 1;

                    this.totalPages = data.meta.last_page;
                })
                .finally(() => {
                });
        },
        // eslint-disable-next-line func-names
        fetchMoreData: debounce(function (this: any, name: string): void {
            this.fetchData(name);
        }, 300),

    },
    watch: {
        modelmodelValue(value): void {
            if (this.multiple) {
                this.newModelValue = value || [];
            } else {
                this.newModelValue = value ? value.question : '';
            }
        },
    },
});
