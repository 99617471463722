import BuildingTypes from '@/components/BuildingTypes.vue';
import Clients from '@/components/Clients.vue';
import Colors from '@/components/Colors.vue';
import Countries from '@/components/Countries.vue';
import Icons from '@/components/Icons.vue';
import TField from '@/components/TField.vue';
import Platforms from '@/components/Platforms.vue';
import Products from '@/components/Products.vue';
import Regions from '@/components/Regions.vue';
import Filters from '@/components/Filters.vue';
import Tags from '@/components/Tags.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Oruga from '@oruga-ui/oruga-next';
import { bulmaConfig } from '@oruga-ui/theme-bulma';
import * as AllRules from '@vee-validate/rules';
import VueAvatar from '@webzlodimir/vue-avatar';
import QRCodeVue3 from 'qr-code-generator-vue3';
import { QrCapture } from 'vue3-qr-reader';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import moment from 'moment';
import {
    defineRule, Field, Form, ErrorMessage, configure,
} from 'vee-validate';
import { Component, createApp } from 'vue';
import VueAxios from 'vue-axios';
import ProductCategories from '@/components/ProductCategories.vue';
import FormsReportTypes from '@/components/FormsReportTypes.vue';
import AddressForm from '@/components/AddressForm.vue';
import { Loader } from '@googlemaps/js-api-loader';
import InfringementCategories from '@/components/InfringementCategories.vue';
import {
    camelCase, // `camelCase`
    pascalCase, // `PascalCase`
    capitalCase, // `Capital Case`
    headerCase, // `Header-Case`
    titleCase, // `Title Case`
    pathCase, // `path/case`
    snakeCase, // `snake_case`
    paramCase, // `param-case`
    dotCase, // `dot.case`
    noCase, // `no case`
    constantCase, // `CONSTANT_CASE`
    lowerCase, // `lower case`
    lowerCaseFirst, // `lOWER CASE FIRST`
    upperCase, // `UPPER CASE`
    upperCaseFirst, // `Upper case first`
    swapCase, // `sWaP cAsE` -> `SwAp CaSe`
    sentenceCase,
    isLowerCase,
    isUpperCase,
} from 'text-case';

import Infringements from '@/components/Infringements.vue';
import Users from '@/components/Users.vue';
import str from './store/main';

import { loadLocaleMessages, setupI18n } from './i18n';
import stl from './styles.sass';

export const store = str;
export const styles = stl;

export const http = axios;

export const create = (component: Component) => {
    const app = createApp(component);

    const i18n = setupI18n({
        locale: 'en',
    });
    console.log(AllRules)
    Object.keys(AllRules)
        .forEach((rule) => {
            defineRule(rule, AllRules[rule]);
        });

    const loader = new Loader({
        apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY as string,
        version: 'weekly',
        libraries: ['places'],
    });
    loader.load();

    app.config.globalProperties.$store = store;
    app.config.globalProperties.$moment = moment;

    axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

    axios.interceptors.request.use(
        (c: InternalAxiosRequestConfig) => {
            const config: InternalAxiosRequestConfig = c;
            if (config.method === 'get' && config.params) {
                window.Object.keys(config.params)
                    .forEach((param: any) => {
                        if (typeof config.params[param] === 'boolean') {
                            config.params[param] = config.params[param] ? 1 : 0;
                        }
                    });
            }

            if (
                config.method === 'get'
                && config.params
                && config.params.sortBy
                && config.params.orderBy
            ) {
                config.params.sort = `${config.params.sortBy}:${config.params.orderBy}`;
                config.params.per_page = config.params.per_page || 20;
                delete config.params.sortBy;
                delete config.params.orderBy;
            }
            return config;
        },
        (err) => {
            Promise.reject(err);
        },
    );

    axios.interceptors.response.use(
        (response: AxiosResponse) => {
            if (response.status >= 400) {
                return Promise.reject(response);
            }
            return Promise.resolve(response);
        },
        (error: AxiosError) => Promise.reject(error),
    );

    const MAX_REQUESTS_COUNT = 3;
    const INTERVAL_MS = 10;
    let PENDING_REQUESTS = 0;

    axios.interceptors.request.use(
        (config) => new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
                    PENDING_REQUESTS += 1;
                    clearInterval(interval);
                    resolve(config);
                }
            }, INTERVAL_MS);
        }),
    );

    axios.interceptors.response.use(
        (response) => {
            PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
            return Promise.resolve(response);
        },
        (error) => {
            PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
            return Promise.reject(error);
        },
    );

    app.config.globalProperties.$filters = {
        moment,
        truncate(value: string, length: number, ellipsis: string = '...') {
            if (value && value.length > length) return value.substring(0, length) + ellipsis;
            return value;
        },
        camelCase,
        pascalCase,
        capitalCase,
        headerCase,
        titleCase,
        pathCase,
        snakeCase,
        paramCase,
        dotCase,
        noCase,
        constantCase,
        lowerCase,
        lowerCaseFirst,
        upperCase,
        upperCaseFirst,
        swapCase,
        sentenceCase,
        isLowerCase,
        isUpperCase,
    };

    app.use(VueAxios, axios);

    app.use(i18n);

    app.use(store);

    app.use(Oruga, {
        ...bulmaConfig,
        iconPack: 'fas',
        iconComponent: 'vue-fontawesome',
        autocomplete: {
            override: true,
            rootClass: 'autocomplete control',
            menuClass: 'dropdown-menu dropdown-content',
            menuPositionClass: 'is-opened-',
            itemClass: 'dropdown-item',
            itemHoverClass: 'is-hovered',
            itemEmptyClasses: 'is-disabled',
            itemGroupTitleClass: 'has-text-weight-bold',
            expandedClass: 'is-expanded',
        },
        modal: {
            scroll: 'clip',
            override: false,
            rootClass: (_: string, { props }: any) => {
                const classes = ['modal'];
                if (props.active || props.programmatic) classes.push('is-active');
                return classes.join(' ');
            },
            overlayClass: 'modal-background',
            contentClass: 'animation-content',
            closeClass: 'modal-close is-large',
            fullScreenClass: 'is-full-screen',
        },
    });

    library.add(fas);

    app.component('VueFontawesome', FontAwesomeIcon);
    app.component('t-field', TField);
    app.component('products', Products);
    app.component('forms-report-types', FormsReportTypes);
    app.component('clients', Clients);
    app.component('users', Users);
    app.component('building-types', BuildingTypes);
    app.component('colors', Colors);
    app.component('platforms', Platforms);
    app.component('countries', Countries);
    app.component('product-categories', ProductCategories);
    app.component('infringement-categories', InfringementCategories);
    app.component('infringements', Infringements);
    app.component('regions', Regions);
    app.component('tags', Tags);
    app.component('icons', Icons);
    app.component('address-form', AddressForm);
    app.component('filters', Filters);

    app.component('vee-field', Field);
    app.component('vee-form', Form);
    app.component('vee-error-message', ErrorMessage);

    app.component('avatar', VueAvatar);
    app.component('qr-code', QRCodeVue3);
    app.component('qr-reader', QrCapture);

    return app;
};
