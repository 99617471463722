
import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';

export default defineComponent({
    mixins: [Base],
    emits: ['update:modelValue'],
    methods: {
        getValue(param) {
            let p = param;
            switch (typeof p) {
            case 'object':
                if (!Array.isArray(p)) {
                    p = [p];
                }
                p = p.map((i) => i.name || i.title || i.id)
                    .join(', ');

                if (!p.length) {
                    p = 'All';
                }
                break;
            case 'number':
                p = p.toString();
                break;
            case 'boolean':
                p = p ? this.$t('yes') : this.$t('no');
                break;
            case 'string':
                p = this.$filters.noCase(p);
                break;
            }

            return p;
        },
    },
    props: {
        modelValue: {
            type: Object,
            default: () => {
            },
        },
    },
});
