import { createI18n } from 'vue-i18n';

export const SUPPORT_LOCALES = ['en', 'fr', 'nl'];

export async function loadLocaleMessages(i18n, locale) {
    const messages = await import(`./locales/${locale}.json`);

    i18n.global.setLocaleMessage(locale, messages.default);
    return messages;
}

export function setupI18n(options) {
    const i18n = createI18n(options);

    const promises = [];

    Object.values(SUPPORT_LOCALES).forEach((locale) => {
        promises.push(loadLocaleMessages(i18n, locale));
    });

    Promise.all(promises, () => {
        i18n.global.locale = options.locale;
    });

    return i18n;
}
