import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: () => import('../pages/Dashboard.vue'),
    },
    {
        path: '/projects',
        name: 'projects.list',
        component: () => import('../pages/projects/List.vue'),
        children: [
            {
                path: 'edit/:id',
                name: 'projects.edit',
                component: () => import('../pages/projects/Edit.vue'),
            },
            {
                path: 'delete/:id',
                name: 'projects.delete',
                component: () => import('../pages/projects/Delete.vue'),
            },
            {
                path: 'create',
                name: 'projects.create',
                component: () => import('../pages/projects/Create.vue'),
            },
        ],
    },
    {
        path: '/projects/:project_id/steps',
        name: 'projects.steps.list',
        component: () => import('../pages/projects/steps/List.vue'),
        children: [
            {
                path: 'create',
                name: 'projects.steps.create',
                component: () => import('../pages/projects/steps/Create.vue'),
            },
            {
                path: 'edit/:id',
                name: 'projects.steps.edit',
                component: () => import('../pages/projects/steps/Edit.vue'),
            },
            {
                path: 'delete/:id',
                name: 'projects.steps.delete',
                component: () => import('../pages/projects/steps/Delete.vue'),
            },
        ],
    },
    {
        path: '/clients',
        name: 'clients.list',
        component: () => import('../pages/clients/List.vue'),
        children: [
            {
                path: 'edit/:id',
                name: 'clients.edit',
                component: () => import('../pages/clients/Edit.vue'),
            },
            {
                path: 'delete/:id',
                name: 'clients.delete',
                component: () => import('../pages/clients/Delete.vue'),
            },
            {
                path: 'create',
                name: 'clients.create',
                component: () => import('../pages/clients/Create.vue'),
            },
        ],
    },
    {
        path: '/objects',
        name: 'objects.list',
        component: () => import('../pages/objects/List.vue'),
        children: [
            {
                path: 'edit/:id',
                name: 'objects.edit',
                component: () => import('../pages/objects/Edit.vue'),
            },
            {
                path: 'delete/:id',
                name: 'objects.delete',
                component: () => import('../pages/objects/Delete.vue'),
            },
            {
                path: 'create',
                name: 'objects.create',
                component: () => import('../pages/objects/Create.vue'),
            },
            {
                path: 'import',
                name: 'objects.import',
                component: () => import('../pages/objects/Import.vue'),
            },
            {
                path: 'export',
                name: 'objects.export',
                component: () => import('../pages/objects/Export.vue'),
            },
        ],
    },
    {
        path: '/infringements',
        name: 'infringements.list',
        component: () => import('../pages/infringements/List.vue'),
        children: [
            {
                path: 'edit/:id',
                name: 'infringements.edit',
                component: () => import('../pages/infringements/Edit.vue'),
            },
            {
                path: 'delete/:id',
                name: 'infringements.delete',
                component: () => import('../pages/infringements/Delete.vue'),
            },
            {
                path: 'create',
                name: 'infringements.create',
                component: () => import('../pages/infringements/Create.vue'),
            },
            {
                path: 'import',
                name: 'infringements.import',
                component: () => import('../pages/infringements/Import.vue'),
            },
            {
                path: 'export',
                name: 'infringements.export',
                component: () => import('../pages/infringements/Export.vue'),
            },
        ],
    },
    {
        path: '/product-categories',
        name: 'products.categories.list',
        component: () => import('@/pages/products/categories/List.vue'),
        children: [
            {
                path: 'edit/:id',
                name: 'products.categories.edit',
                component: () => import('@/pages/products/categories/Edit.vue'),
            },
            {
                path: 'delete/:id',
                name: 'products.categories.delete',
                component: () => import('@/pages/products/categories/Delete.vue'),
            },
            {
                path: 'create',
                name: 'products.categories.create',
                component: () => import('@/pages/products/categories/Create.vue'),
            },

        ],
    },
    {
        path: '/infringement-categories',
        name: 'infringements.categories.list',
        component: () => import('@/pages/infringements/categories/List.vue'),
        children: [
            {
                path: 'edit/:id',
                name: 'infringements.categories.edit',
                component: () => import('@/pages/infringements/categories/Edit.vue'),
            },
            {
                path: 'delete/:id',
                name: 'infringements.categories.delete',
                component: () => import('@/pages/infringements/categories/Delete.vue'),
            },
            {
                path: 'create',
                name: 'infringements.categories.create',
                component: () => import('@/pages/infringements/categories/Create.vue'),
            },
        ],
    },
    {
        path: '/products',
        name: 'products.list',
        component: () => import('../pages/products/List.vue'),
        children: [
            {
                path: 'edit/:id',
                name: 'products.edit',
                component: () => import('../pages/products/Edit.vue'),
            },
            {
                path: 'delete/:id',
                name: 'products.delete',
                component: () => import('../pages/products/Delete.vue'),
            },
            {
                path: 'create',
                name: 'products.create',
                component: () => import('../pages/products/Create.vue'),
            },
            {
                path: 'import',
                name: 'products.import',
                component: () => import('../pages/products/Import.vue'),
            },
            {
                path: 'export',
                name: 'products.export',
                component: () => import('../pages/products/Export.vue'),
            },
        ],
    },
    {
        path: '/platforms',
        name: 'platforms.list',
        component: () => import('../pages/platforms/List.vue'),
        children: [
            {
                path: 'edit/:id',
                name: 'platforms.edit',
                component: () => import('../pages/platforms/Edit.vue'),
            },
            {
                path: 'delete/:id',
                name: 'platforms.delete',
                component: () => import('../pages/platforms/Delete.vue'),
            },
            {
                path: 'create',
                name: 'platforms.create',
                component: () => import('../pages/platforms/Create.vue'),
            },
        ],
    },
    {
        path: '/users',
        name: 'users.list',
        component: () => import('../pages/users/List.vue'),
        children: [
            {
                path: 'edit/:id',
                name: 'users.edit',
                component: () => import('../pages/users/Edit.vue'),
            },
            {
                path: 'delete/:id',
                name: 'users.delete',
                component: () => import('../pages/users/Delete.vue'),
            },
            {
                path: 'create',
                name: 'users.create',
                component: () => import('../pages/users/Create.vue'),
            },
        ],
    },
    {
        path: '/jobs',
        name: 'jobs.list',
        component: () => import('../pages/jobs/List.vue'),
        children: [
            {
                path: 'create',
                name: 'jobs.create',
                component: () => import('../pages/jobs/Create.vue'),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
