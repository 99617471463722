

import { debounce } from 'ts-debounce';

import { IRegion, IRegionsResponse } from '@/interfaces/region';

import { defineComponent, PropType } from 'vue';

import Base from '@/mixins/Base.vue';

export default defineComponent({
    mixins: [Base],
    props: {

        appendToBody: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: undefined as any | PropType<unknown> | PropType<Array<any>>,
            default: undefined,
        },
        countryId: {
            type: [Number, String],
            default: null,
        },
    },
    data() {
        return {
            name: '',
            page: 1,
            totalPages: 1,
            newModelValue: undefined,
            data: [] as Array<IRegion>,
        };
    },
    mounted() {
        this.newModelValue = this.modelValue ? this.modelValue.name : '';

        if (!this.modelValue) this.$emit('update:modelValue', null);
    },
    methods: {
        reset(): void {
            this.page = 1;
            this.totalPages = 1;
            this.name = '';
            this.data = [];
            this.fetchData();
        },
        onFocus(): void {
            this.reset();
        },
        onSelect(obj: IRegion): void {
            if (!obj) this.reset();
            this.$emit('update:modelValue', obj || null);
        },
        // eslint-disable-next-line func-names
        onTyping: debounce(function (this: any, name: string = ''): void {
            this.fetchData(name);
        }, 500),

        fetchData(name: string = '') {
            if (this.name !== name) {
                this.name = name;
                this.page = 1;
                this.totalPages = 1;
                this.data = [];
            }

            if (this.page > this.totalPages) {
                return;
            }

            this.get<IRegionsResponse>('regions', {
                params: {
                    filter: this.name,
                    page: this.page,
                    country_id: this.countryId,
                },
            })
                .then(({ data }) => {
                    this.data.push(...data.data);
                    this.page += 1;

                    this.totalPages = data.meta.last_page;
                });
        },
        // eslint-disable-next-line func-names
        fetchMoreData: debounce(function (this: any, name: string): void {
            this.fetchData(name);
        }, 300),

    },
    watch: {
        modelValue(value): void {
            this.newModelValue = value ? value.name : '';
        },
        countryId(): void {
            this.reset();
        },
    },
});
