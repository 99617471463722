import { IUser } from '@/interfaces/user';
import { createStore } from 'vuex';

export default createStore({
    state: {
        env: process.env,
        user: undefined,
        currentLocale: 'en',
        locales: {
            en: {
                code: 'en',
                name: 'English',
                flag: `${process.env.VUE_APP_URL}/img/flags/en.svg`,
            },
            nl: {
                code: 'nl',
                name: 'Nederlands',
                flag: `${process.env.VUE_APP_URL}/img/flags/nl.svg`,
            },
            fr: {
                code: 'fr',
                name: 'Français',
                flag: `${process.env.VUE_APP_URL}/img/flags/fr.svg`,
            },
        },
        privateProfessionalOptions: {
            private: {
                name: 'Private',
                value: 'private',
            },
            professional: {
                name: 'Professional',
                value: 'professional',
            },
            both: {
                name: 'Both',
                value: 'both',
            },
        },
        rateTypeOptions: [
            {
                name: 'Coard',
                value: 'board',
            },
            {
                name: 'Cabine',
                value: 'cabine',
            },
            {
                name: 'Cable',
                value: 'cable',
            },
            {
                name: 'Charging station',
                value: 'charging_station',
            },
            {
                name: 'Circuit',
                value: 'circuit',
            },
            {
                name: 'Connection point',
                value: 'connection_point',
            },
            {
                name: 'Detector',
                value: 'detector',
            },
            {
                name: 'Down conductor',
                value: 'down_conductor',
            },
            {
                name: 'Fire hose reel',
                value: 'fire_hose_reel',
            },
            {
                name: 'Fixture',
                value: 'fixture',
            },
            {
                name: 'Foodtruck',
                value: 'foodtruck',
            },
            {
                name: 'h',
                value: 'h',
            },
            {
                name: 'hour',
                value: 'hour',
            },
            {
                name: 'Housing unit',
                value: 'housing_unit',
            },
            {
                name: 'Hydrant',
                value: 'hydrant',
            },
            {
                name: 'Item',
                value: 'item',
            },
            {
                name: 'km',
                value: 'km',
            },
            {
                name: 'kVA',
                value: 'kVA',
            },
            {
                name: 'Link',
                value: 'link',
            },
            {
                name: 'm2',
                value: 'm2',
            },
            {
                name: 'Measuring point',
                value: 'measuring_point',
            },
            {
                name: 'MO',
                value: 'MO',
            },
            {
                name: 'N°',
                value: 'n',
            },
            {
                name: 'Object',
                value: 'object',
            },
            {
                name: 'Optimizer',
                value: 'optimizer',
            },
            {
                name: 'Participant',
                value: 'participant',
            },
            {
                name: 'Point',
                value: 'point',
            },
            {
                name: 'Puncture',
                value: 'puncture',
            },
            {
                name: 'Quick extinguisher',
                value: 'quick_extinguisher',
            },
            {
                name: 'Quotation',
                value: 'quotation',
            },
            {
                name: 'Relay',
                value: 'relay',
            },
            {
                name: 'Room',
                value: 'room',
            },
            {
                name: 'Sample',
                value: 'sample',
            },
            {
                name: 'Sample 1',
                value: 'sample1',
            },
            {
                name: 'Sample 2',
                value: 'sample2',
            },
            {
                name: 'Smoke dome',
                value: 'smoke dome',
            },
            {
                name: 'Sprinkler installation',
                value: 'sprinkler installation',
            },
            {
                name: 'Stopping point',
                value: 'stopping point',
            },
            {
                name: 'Test',
                value: 'test',
            },
            {
                name: 'Unit',
                value: 'unit',
            },
        ],
        surveyRelationOptions: [
            {
                name: 'When this product is ordered in Wizard / require before order',
                value: 'wizard-before',
            },
            {
                name: 'When this product is ordered in Wizard / send post link',
                value: 'wizard-post-link',
            },
            {
                name: 'When this product is assigned to Object in Object Manager (product contract)',
                value: 'object-manager-product-contract',
            },
            {
                name: 'When this product is subject of inspection in Object Manager',
                value: 'object-manager-inspection',
            },
        ],
        surveyByOptions: [
            {
                name: 'Client user',
                value: 'client-user',
            },
            {
                name: 'Engineer',
                value: 'engineer',
            },
        ],
    },
    mutations: {
        GET_USER(state: any, data: IUser) {
            state.user = data as IUser;
        },
    },
    getters: {
        user(state): IUser {
            return state.user;
        },
        locales(state) {
            return state.locales;
        },
    },
    actions: {
        getUser(context: any, data: IUser) {
            context.commit('GET_USER', data);
        },
    },
    modules: {},
});
