
import { useProgrammatic } from '@oruga-ui/oruga-next';
import { AxiosError, AxiosResponse } from 'axios';
import { defineComponent } from 'vue';
import { IUser } from '../interfaces/user';
import store from '../store/main';

export interface SuccessResponse {
    status: number;
}

export interface ErrorResponse {
    status: number;
    message: string;
    errors?: Array<any> | any;
}

export default defineComponent({

    data() {
        return {
            loading: false,
            errors: false,
        };
    },
    props: {
        error: {
            type: [Object, Array, Boolean, String],
            default() {
                return false;
            },
        },
    },
    watch: {
        error(value: any) {
            this.errors = value;
        },
    },
    methods: {
        get<T = any>(url: string, config?: any): Promise<AxiosResponse<T>> {
            this.initRequest();
            return new Promise<AxiosResponse<T>>((resolve, reject) => {
                this.axios
                    .get<T>(url, config)
                    .catch((err) => {
                        this.handleError(err);
                        reject(err);
                    })
                    .finally(() => {
                        this.handleFinally();
                    })
                    .then((response) => {
                        if (response) resolve(response);
                    });
            });
        },
        post<T = any>(url: string, data?: any, config: any = null): Promise<AxiosResponse<T>> {
            this.initRequest();
            return new Promise<AxiosResponse<T>>((resolve, reject) => {
                this.axios
                    .post<T>(url, data, config)
                    .catch((err) => {
                        console.log(err);
                        this.handleError(err);
                        reject(err);
                    })
                    .finally(() => {
                        this.handleFinally();
                    })
                    .then((response) => {
                        if (response) resolve(response);
                        this.handleSuccess('Saved !');
                    });
            });
        },
        put<T = any>(url: string, data?: any, config: any = null): Promise<AxiosResponse<T>> {
            this.initRequest();

            return new Promise<AxiosResponse<T>>((resolve, reject) => {
                this.axios
                    .put<T>(url, data, config)
                    .catch((err) => {
                        this.handleError(err);
                        reject(err);
                    })
                    .finally(() => {
                        this.handleFinally();
                    })
                    .then((response) => {
                        if (response) resolve(response);
                        this.handleSuccess('Updated !');
                    });
            });
        },
        delete<T = any>(url: string, config?: any): Promise<AxiosResponse<T>> {
            this.initRequest();
            return new Promise<AxiosResponse<T>>((resolve) => {
                this.axios
                    .delete<T>(url, config)
                    .catch(this.handleError)
                    .finally(this.handleFinally)
                    .then((response) => {
                        if (response) resolve(response);
                        this.handleSuccess('Deleted !');
                    });
            });
        },
        // findVeeObservers(obj: any) {
        //     let found = obj.$_veeObserver;
        //
        //     if (!found && obj.$children) {
        //         for (let i = 0; i < obj.$children.length; i += 1) {
        //             found = this.findVeeObservers(obj.$children[i]);
        //             if (found) break;
        //         }
        //     }
        //
        //     return found;
        // },
        initRequest() {
            this.errors = false;
            this.loading = true;
        },
        handleError(err: AxiosError) {
            if (err.response) {
                const response: ErrorResponse = err.response.data as ErrorResponse;

                if (response.errors && this.$refs.form) {
                    (this.$refs.form as any).setErrors(response.errors);
                }

                this.$emit('error', err.response.data);
            } else {
                this.$emit('error', { message: err.message });
            }
        },
        handleFinally() {
            this.loading = false;
        },
        handleSuccess(message = '') {
            const { oruga } = useProgrammatic();
            oruga.notification.open({
                duration: 5000,
                closable: true,
                message,
                variant: 'success',
                position: 'top',
                animation: 'slide',
                queue: true,
            });
        },
    },
});
