import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "columns is-multiline is-fullwidth" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_icon = _resolveComponent("o-icon")!
  const _component_o_button = _resolveComponent("o-button")!
  const _component_o_tab_item = _resolveComponent("o-tab-item")!
  const _component_o_tabs = _resolveComponent("o-tabs")!
  const _component_o_dropdown_item = _resolveComponent("o-dropdown-item")!
  const _component_o_dropdown = _resolveComponent("o-dropdown")!

  return (_openBlock(), _createBlock(_component_o_dropdown, _mergeProps({
    scrollable: _ctx.isScrollable,
    style: { maxHeight: 300 }
  }, _ctx.$attrs), {
    trigger: _withCtx(() => [
      _createVNode(_component_o_button, _normalizeProps(_guardReactiveProps(_ctx.$attrs)), {
        default: _withCtx(() => [
          (_ctx.newValue)
            ? (_openBlock(), _createBlock(_component_o_icon, {
                key: 0,
                icon: _ctx.newValue
              }, null, 8, ["icon"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(" Choose an icon ")
              ], 64))
        ]),
        _: 1
      }, 16)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_o_dropdown_item, {
        clickable: false,
        style: { width: 320 + 'px' }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_o_tabs, { size: "small" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group, g) => {
                return (_openBlock(), _createBlock(_component_o_tab_item, {
                  key: g,
                  label: group.label
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.icons, (icon, i) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: i,
                          class: _normalizeClass([{ 'is-selected': icon === _ctx.newValue }, "column is-1"]),
                          onClick: ($event: any) => (_ctx.onSelect(icon))
                        }, [
                          _createVNode(_component_o_icon, { icon: icon }, null, 8, ["icon"])
                        ], 10, _hoisted_2))
                      }), 128))
                    ])
                  ]),
                  _: 2
                }, 1032, ["label"]))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_o_button, {
            size: "small",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSelect(null)))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Clear ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 16, ["scrollable"]))
}